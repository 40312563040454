import {Admin, CustomRoutes, Resource} from "react-admin";
import authProvider from "../../data-providers/auth-provider";
import dataProvider from "../../data-providers/data-provider";
import {theme} from "../../global/styles";
import ExperienceCreate from "../Experiences/ExperienceCreate";
import ExperienceEdit from "../Experiences/ExperienceEdit";
import ExperienceList from "../Experiences/ExperienceList";
import CustomLayout from "../Layout/CustomLayout";
import LicenseCreate from "../Licenses/LicenseCreate";
import LicenseEdit from "../Licenses/LicenseEdit";
import LicenseList from "../Licenses/LicenseList";
import CustomLogin from "../Login/CustomLogin";
import NotFound from "../NotFound/NotFound";
import OrganizationCreate from "../Organizations/OrganizationCreate";
import OrganizationEdit from "../Organizations/OrganizationEdit";
import OrganizationList from "../Organizations/OrganizationList";
import UserCreate from "../Users/UserCreate";
import UserEdit from "../Users/UserEdit";
import UserList from "../Users/UserList";
import {hasAccess} from "../../global/permissions";
import {RoleID} from "../../models/role";
import TagList from "../Tags/TagList";
import TagCreate from "../Tags/TagCreate";
import TagEdit from "../Tags/TagEdit";
import {Route} from "react-router-dom";
import {Profile} from "../Profile/Profile";
import ExperienceGroupsCreate from "../ExperienceGroups/ExperienceGroupsCreate";
import ExperienceGroupsList from "../ExperienceGroups/ExperienceGroupsList";
import ExperienceGroupsEdit from "../ExperienceGroups/ExperienceGroupsEdit";
import Dashboard from "../Dashboard/Dashboard";
import LoadingPage from "../Loading/LoadingPage";
import {i18nProvider} from "../../i18n/i18nProvider";
import ServiceLicenseList from "../ServiceLicenses/ServiceLicenseList";
import {ServiceLicenseCreate, ServiceLicenseEdit} from "../ServiceLicenses/ServiceLicenseForm";
import React from "react";
import DeviceList from "../Devices/DeviceList";
import "../../global/axios.config";

const App = () => {
    return (
        <Admin
            title="UniVR Manager"
            dataProvider={dataProvider}
            authProvider={authProvider}
            loginPage={CustomLogin}
            catchAll={NotFound}
            theme={theme}
            layout={CustomLayout}
            i18nProvider={i18nProvider}
            dashboard={Dashboard}
            loading={LoadingPage}
        >
            {(permissions: RoleID[]) => (
                <>
                    <Resource
                        name="experiences"
                        options={{label: "Expériences"}}
                        recordRepresentation="name"
                        list={
                            hasAccess({name: "experiences", crudType: "list"}, permissions)
                                ? ExperienceList
                                : null
                        }
                        create={
                            hasAccess(
                                {name: "experiences", crudType: "create"},
                                permissions
                            )
                                ? ExperienceCreate
                                : null
                        }
                        edit={
                            hasAccess({name: "experiences", crudType: "edit"}, permissions)
                                ? ExperienceEdit
                                : null
                        }
                    >
                        {/* License list route */}
                        <Route
                            path="/:id/licenses"
                            element={<LicenseList parentResource="experiences"/>}
                        />
                        {/* License create route */}
                        <Route
                            path="/:id/licenses/create"
                            element={<LicenseCreate parentResource="experiences"/>}
                        />
                        {/* License edit route */}
                        <Route
                            path="/:id/licenses/:licenseId"
                            element={<LicenseEdit parentResource="experiences"/>}
                        />
                    </Resource>

                    <Resource
                        name="experience-groups"
                        options={{label: "Groupes d'expériences"}}
                        recordRepresentation="name"
                        list={
                            hasAccess(
                                {name: "experience-groups", crudType: "list"},
                                permissions
                            )
                                ? ExperienceGroupsList
                                : null
                        }
                        create={
                            hasAccess(
                                {name: "experience-groups", crudType: "create"},
                                permissions
                            )
                                ? ExperienceGroupsCreate
                                : null
                        }
                        edit={
                            hasAccess(
                                {name: "experience-groups", crudType: "edit"},
                                permissions
                            )
                                ? ExperienceGroupsEdit
                                : null
                        }
                    >
                        {/* License list route */}
                        <Route
                            path="/:id/licenses"
                            element={<LicenseList parentResource="organizations"/>}
                        />
                        {/* License create route */}
                        <Route
                            path="/:id/licenses/create"
                            element={<LicenseCreate parentResource="organizations"/>}
                        />
                        {/* License edit route */}
                        <Route
                            path="/:id/licenses/:licenseId"
                            element={<LicenseEdit parentResource="organizations"/>}
                        />
                        {/* License organization list route */}
                        <Route
                            path="/:id/organizations"
                            element={<OrganizationList parentResource="licenses" />}
                        />
                    </Resource>

                    <Resource
                        name="organizations"
                        options={{label: "Organisations"}}
                        recordRepresentation="name"
                        list={
                            hasAccess(
                                {name: "organizations", crudType: "list"},
                                permissions
                            )
                                ? OrganizationList
                                : null
                        }
                        create={
                            hasAccess(
                                {name: "organizations", crudType: "create"},
                                permissions
                            )
                                ? OrganizationCreate
                                : null
                        }
                        edit={
                            hasAccess(
                                {name: "organizations", crudType: "edit"},
                                permissions
                            )
                                ? OrganizationEdit
                                : null
                        }
                    >
                        {/* License list route */}
                        <Route
                            path="/:id/licenses"
                            element={<LicenseList parentResource="organizations"/>}
                        />
                        {/* License create route */}
                        <Route
                            path="/:id/licenses/create"
                            element={<LicenseCreate parentResource="organizations"/>}
                        />
                        {/* License edit route */}
                        <Route
                            path="/:id/licenses/:licenseId"
                            element={<LicenseEdit parentResource="organizations"/>}
                        />

                        {/* User list route */}
                        <Route path="/:id/users" element={<UserList/>}/>
                        {/* User create route */}
                        <Route path="/:id/users/create" element={<UserCreate/>}/>
                        {/* User edit route */}
                        <Route path="/:id/users/:userId" element={<UserEdit/>}/>

                        <Route
                            path="/:id/service-licenses"
                            element={<ServiceLicenseList/>}
                        />
                        <Route
                            path="/:id/service-licenses/create"
                            element={<ServiceLicenseCreate/>}
                        />
                        <Route
                            path="/:id/service-licenses/:serviceLicenseId"
                            element={<ServiceLicenseEdit/>}
                        />
                    </Resource>

                    <Resource
                        name="users"
                        recordRepresentation="initials"
                        options={{label: "Utilisateurs"}}
                    />

                    <Resource name="licenses" options={{label: "Licences"}}/>

                    <Resource
                        name="tags"
                        recordRepresentation="name"
                        list={
                            hasAccess({name: "tags", crudType: "list"}, permissions)
                                ? TagList
                                : null
                        }
                        create={
                            hasAccess({name: "tags", crudType: "create"}, permissions)
                                ? TagCreate
                                : null
                        }
                        edit={
                            hasAccess({name: "tags", crudType: "edit"}, permissions)
                                ? TagEdit
                                : null
                        }
                    />

                    <Resource
                        name="devices"
                        recordRepresentation="name"
                        list={
                            hasAccess({name: "devices", crudType: "list"}, permissions)
                                ? DeviceList
                                : null
                        }
                        create={null}
                        edit={null}
                    />
                </>
            )}

            <CustomRoutes>
                <Route path="/profile" element={<Profile/>}/>
            </CustomRoutes>
        </Admin>
    );
};

export default App;
