import {DeleteButton, DeleteWithConfirmButton, SaveButton, Toolbar, ToolbarProps, useRecordContext, useTranslate} from "react-admin";
import React from "react";
import {Stack} from "@mui/material";

interface CustomToolbarProps extends ToolbarProps {
    deleteRedirect?: string;
}

const CustomSimpleToolbar: React.FC<CustomToolbarProps> = ({deleteRedirect, ...props}) => {
    const record = useRecordContext();
    const translate = useTranslate();

    return (
        <Toolbar {...props}>
            <Stack direction="row" gap={2} justifyContent={record ? "space-between" : "flex-end"} py={2} width={"100%"}>
                {record &&
                    <DeleteWithConfirmButton
                    size={"medium"}
                    icon={null}
                    color={"error"}
                    confirmTitle={`Suppression de ${record.name}`}
                    label={translate("ra.action.delete")}
                    redirect={deleteRedirect}
                    mutationMode={"undoable"}
                  />
                }
                <SaveButton icon={null} variant={"contained"} size={"medium"} alwaysEnable/>
            </Stack>
        </Toolbar>
    )
};

export default CustomSimpleToolbar;